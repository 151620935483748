import { Link } from "gatsby";
import {
    Box,
    Heading,
    Container,
    Text,
    Button,
    Stack,
    Highlight
} from '@chakra-ui/react';
export { Head } from "../seo";

export default function ParkingOperators() {
    return (
        <>
            <Box bg="gray.50">
                <Container maxW={'3xl'} >
                    <Stack
                        as={Box}
                        textAlign={'left'}
                        spacing={{ base: 8, md: 14 }}
                        py={12}
                    >
                        <Heading
                        textTransform={'uppercase'}
                        color={'blue.400'}
                        fontWeight={600}
                        fontSize="md"
                        as="h1"
                        bg={'blue.50'}
                        p={2}
                        alignSelf={'flex-start'}
                        rounded={'md'}>
                            Parkify for Parking Operators
                        </Heading>
                        <Heading
                            fontWeight={800}
                            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                            as="h2"
                        >
                            <Highlight
                                query='Parking Revenue'
                                styles={{ color: "purple.500" }}
                            >
                                Increase Parking Revenue
                            </Highlight>
                        </Heading>
                        <Text fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
                            Parkify brings more revenue into your parking facility by putting crucial parking information
                            exactly where parkers need it.
                        </Text>
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={3}
                        >
                            <Button
                                as={Link}
                                to={`/features`}
                                variant={'outline'}
                                rounded="lg"
                                colorScheme="purple"
                            >
                                Learn More
                            </Button>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box bgGradient='linear(to-t, purple.200, purple.500)'>
                <Container maxW={'3xl'}>
                    <Stack
                        as={Box}
                        textAlign={'left'}
                        spacing={{ base: 8, md: 14 }}
                        py={12}
                    >
                        <Heading
                            fontWeight={700}
                            color={"gray.50"}
                            as="h2"
                            fontSize={{ base: '2xl', sm: '3xl', md: '6xl' }}
                        >
                            <Highlight
                                query='Asset-Lite'
                                styles={{ color: "brand.500" }}
                            >
                                Asset-Lite Solution
                            </Highlight>
                        </Heading>
                        <Text color={'gray.50'} fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
                            Parkify is a cloud-based software solution. Maintenance and updates are managed automatically.
                            Parkify can be turned on without any disruption to your facility's service.
                        </Text>
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={3}
                            float={"right"}
                        >
                            <Button
                                as={Link}
                                to={`/features`}
                                variant={'outline'}
                                rounded="lg"
                                colorScheme="whiteAlpha"
                            >
                                <Text
                                >
                                    Learn More
                                </Text>
                            </Button>
                        </Stack>
                    </Stack>
                </Container >
            </Box>
            
            <Box bg="gray.50">
                <Container maxW={'3xl'}>
                    <Stack
                        as={Box}
                        textAlign={'left'}
                        spacing={{ base: 8, md: 14 }}
                        py={12}
                    >
                        <Heading
                            fontWeight={700}
                            as="h2"
                            fontSize={{ base: '2xl', sm: '3xl', md: '6xl' }}
                        >
                            <Highlight
                                query='parking operator'
                                styles={{ color: "purple.500" }}
                            >
                                Seemless integration with on-site operations
                            </Highlight>
                        </Heading>
                        <Text fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
                                Parkify is designed to play nicely with your on-site operatation. Parking
                                service continues without additional complexity or training. 
                        </Text>
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={3}
                            float={"right"}
                        >
                            <Button
                                as={Link}
                                to={`/features`}
                                variant={'outline'}
                                rounded="lg"
                                colorScheme="purple"
                            >
                                <Text>
                                    Learn More
                                </Text>
                            </Button>
                        </Stack>
                    </Stack>
                </Container >
            </Box>
        </>
    );
}